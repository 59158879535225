<template>
  <div class="mcr-select">
    <div class="label">{{ label }}</div>

    <div class="select-options">
      <mcr-filter-item
        :label="deselectLabel"
        value=""
        :selected-value="selectedValue"
        @click="onClick"
      ></mcr-filter-item>

      <mcr-filter-item
        v-for="(option, index) of options"
        :key="index"
        :label="option.name"
        :value="option.value"
        :children="option.children"
        :selected-value="selectedValue"
        @click="onClick"
      ></mcr-filter-item>
    </div>
  </div>
</template>

<script>
import McrFilterItem from '@/base/elements/filters/mcrFilterItem';

export default {
  components: {McrFilterItem},
  props: {
    deselectLabel: {type: String, default: 'Any item'},
    label: String,
    name: String,
    selectedValue: String,
    options: Array,
  },
  methods: {
    onClick(selectedValue) {
      this.$emit('click', {filterName: this.name, filterValue: selectedValue});
    },
  },
};
</script>

<style lang="scss" scoped>
.mcr-select {
  .label {
    padding-bottom: 8px;
    color: $secondary-text-color;
  }
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    display: flex;
    .label {
      padding: 0;
      margin-right: 8px;
      &::after {
        content: ':';
      }
    }
    .select-options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      &::v-deep .mcr-filter-item {
        padding: 0;
        margin-right: 8px;
        white-space: nowrap;
      }
    }
  }
}
</style>
