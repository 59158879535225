<template>
  <div class="team-member" @click="click">
    <img
      class="lazyload member_photo"
      :src="getImagePlaceholder(member.imgSrc)"
      :data-src="member.imgSrc"
      width="100"
      height="100"
    />

    <h5>{{ member.name }}</h5>
    <span>{{ member.title }}</span>
    <p>{{ trimmedDescription }}</p>
    <a class="read-more">Read More &#10142;</a>
  </div>
</template>

<script>
import {NetworkAPIThumbnailURL} from '@common/network/network.utils';

const MAX_CHARS_TRIM_DESCRIPTION = 120;

export default {
  props: {
    member: Object,
  },
  computed: {
    trimmedDescription() {
      let ellipsis = this.member.description.length > MAX_CHARS_TRIM_DESCRIPTION ? '...' : '';
      return `${this.member.description.substring(0, MAX_CHARS_TRIM_DESCRIPTION)}${ellipsis}`;
    },
  },
  methods: {
    click() {
      this.$emit('click-read-more', this.member);
    },
    getImagePlaceholder(url) {
      return NetworkAPIThumbnailURL + '?url=' + encodeURIComponent(url) + '&quality=10&width=100&height=100';
    },
  },
  name: 'team.member',
};
</script>

<style scoped lang="scss">
$team-member-card-padding: 25px;

.team-member {
  margin-bottom: 15px;

  cursor: pointer;
  box-shadow: $box-shadow-light;
  border-radius: 4px;
  background-color: $background-alternate-color;

  border: 1px solid transparent;
  padding-bottom: 15px;

  &:hover {
    border: 1px solid $active-color;
  }

  > * {
    padding: 0 $team-member-card-padding;
  }

  img {
    display: block;
    width: 100%;
    border-radius: 4px;
    box-shadow: $box-shadow;
    margin: 0 auto;
    padding: 0;
  }
  h5 {
    margin-top: $team-member-card-padding;
    margin-bottom: 2px;
    font-weight: bold;
    text-align: center;
  }
  span {
    display: block;
    color: $title-color;
    text-align: center;
  }

  p {
    color: $text-alternate-color;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .read-more {
    color: $link-color;
    display: block;
    margin-top: calc(#{$paragraph-margin-size} / 2);
  }
}
</style>
