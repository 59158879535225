<template>
  <end-actions>
    <h5><slot>Get in touch to trace your family history today.</slot></h5>
    <mcr-buttons-row>
      <mcr-button-router-link-to-pr toStep="email" label="Get in Touch"></mcr-button-router-link-to-pr>
      <book-call-pr-button :url="bookCallUrl"></book-call-pr-button>
    </mcr-buttons-row>
  </end-actions>
</template>

<script>
import endActions from '@common/elements/buttons/endActions';
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import mcrButtonsRow from '@common/elements/buttons/mcrButtonsRow';

import BookCallPrButton from '@/base/elements/buttons/BookCallPrButton';

export default {
  props: {
    bookCallUrl: {type: String, default: ''},
  },
  components: {endActions, mcrButtonsRow, McrButtonRouterLinkToPr, BookCallPrButton},
};
</script>

<style scoped></style>
