<template>
  <div class="filters-container">
    <mcr-filter
      v-for="(filter, index) of filters"
      :key="index"
      :label="filter.label"
      :name="filter.name"
      :options="filter.options"
      :selectedValue="pressArticlesActiveFiltersState[filter.name]"
      :deselect-label="filter.deselectLabel"
      @click="change"
    ></mcr-filter>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import McrFilter from '@/base/elements/filters/mcrFilter';

export default {
  computed: {
    ...mapGetters([
      'pressArticlesLanguageFiltersState',
      'pressArticlesCategoryFiltersState',
      'pressArticlesActiveFiltersState',
    ]),
    filters() {
      return [
        {
          name: 'category',
          label: 'Category',
          options: this.pressArticlesCategoryFiltersState,
          deselectLabel: 'Any category',
        },
        {
          name: 'language',
          label: 'Language',
          options: this.pressArticlesLanguageFiltersState,
          deselectLabel: 'Any language',
        },
      ];
    },
  },
  methods: {
    change({filterName, filterValue}) {
      this.mutateActiveFilters(filterName, filterValue);
    },
    mutateActiveFilters(filterName, filterValue) {
      if (filterValue === this.pressArticlesActiveFiltersState[filterName]) {
        return this.$store.commit('removePressArticleFilterState', filterName);
      }
      return this.$store.commit('modifyPressArticleFilterState', {filterName, filterValue});
    },
  },
  name: 'filtersContainer',
  components: {McrFilter},
};
</script>

<style scoped lang="scss">
.filters-container::v-deep .mcr-filter-item {
  &.depth_1 {
    display: none;
  }
}
</style>
