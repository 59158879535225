<template>
  <div class="mcr-filter-item" :class="classes">
    <div class="label" @click="onClick">{{ label }}</div>
    <mcr-filter-item
      v-for="(child, index) in children || []"
      :key="index"
      :label="child.name"
      :value="child.value"
      :children="child.children"
      :selected-value="selectedValue"
      :depth="depth + 1"
      @click="onChildClick"
    ></mcr-filter-item>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String,
    children: {type: Array, required: false},
    selectedValue: {type: String, default: ''},
    depth: {type: Number, default: 0},
  },
  computed: {
    isActive() {
      return this.selectedValue === this.value;
    },
    classes() {
      let classes = [];
      this.isActive ? classes.push('is-active') : null;
      classes.push(`depth_${this.depth}`);
      return classes;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.value);
    },
    onChildClick(value) {
      this.$emit('click', value);
    },
  },
  name: 'mcr-filter-item',
};
</script>

<style scoped lang="scss">
.mcr-filter-item {
  cursor: pointer;
  font-weight: normal;

  padding: 4px 0;

  &.depth_1 {
    padding-left: 10px;
  }

  &.is-active {
    font-weight: bold;
    color: $text-color;
  }

  .label {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
