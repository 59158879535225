<template>
  <div class="person-quotes-list">
    <person-quote
      v-for="(item, index) of data"
      :key="index"
      :quote="item.quote"
      :imgSource="item.imgSource"
      :name="item.name"
      :description="item.description"
    ></person-quote>
  </div>
</template>

<script>
import PersonQuote from '@/base/elements/personQuote';

export default {
  props: {
    data: Array,
  },
  components: {PersonQuote},
};
</script>

<style lang="scss" scoped>
.person-quotes-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  &::after {
    content: '';
    flex: auto;
  }

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
