<template>
  <div class="news_item">
    <a :href="externalLink" target="_blank">
      <div class="news">
        <div class="bold">{{ heading }}</div>
        <p>{{ category }}</p>
      </div>
      <div class="news_source_image">
        <img :src="imageSrc" />
      </div>
    </a>
    <!--<div v-html="descriptionHtml" class="news_description"></div>-->
  </div>
</template>

<script>
export default {
  props: {
    externalLink: String,
    imageSrc: String,
    heading: String,
    category: String,
    descriptionHtml: String,
  },
  name: 'newsItem',
};
</script>

<style lang="scss" scoped>
.news_item {
  margin-top: 30px;

  a {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: top;

    .news_source_image {
      width: 180px;
      img {
        width: 100%;
      }
    }
    .news {
      width: calc(100% - 200px);
      margin-right: 20px;

      p {
        color: $supplemental-text-color;
        margin-top: 0;
      }
    }
    &:hover {
      text-decoration: none;
      .news {
        .bold {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    a {
      flex-wrap: wrap;
      .news_source_image {
        width: 100%;
      }
      .news {
        margin-right: 0;
        margin-bottom: $paragraph-margin-size;
        width: 100%;
      }
    }
  }
}
</style>
