<template>
  <div class="team-page">
    <div class="info_page">
      <div class="info_section title">
        <div class="info_section_content">
          <h1>Our Team</h1>
        </div>
      </div>
      <div class="info_section" id="team">
        <div class="info_section_content">
          <div class="team">
            <team-member
              v-for="(member, index) in teamMembers"
              :key="index"
              :member="member"
              @click-read-more="handleClickReadMore"
            ></team-member>
          </div>
        </div>
      </div>
      <div class="info_section title">
        <div class="info_section_content">
          <h1>Advisors</h1>
        </div>
      </div>
      <div class="info_section" id="advisors">
        <div class="info_section_content">
          <div class="team">
            <team-member
              v-for="(member, index) in advisorMembers"
              :key="index"
              :member="member"
              @click-read-more="handleClickReadMore"
            ></team-member>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamMemberDetails from '@/components/modules/aboutUs/team/team-member-details';

import TeamMember from './team.member';

export default {
  metaInfo: {
    title: 'Team',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'With staff across 5 countries and offices in Beijing and Guangzhou, we’re the perfect mix of East & West: a fast-growing, multicultural family of history detectives and tech wizards.',
      },
    ],
  },
  name: 'team.page.vue',
  computed: {
    teamMembers() {
      return this.$store.getters.teamMembersState;
    },
    advisorMembers() {
      return this.$store.getters.advisorMembersState;
    },
    modalParams() {
      let width = this.isDesktop ? '800' : '100%';
      let height = 'auto';
      let classes = 'clear_modal';
      return {width, height, classes, scrollable: true};
    },
    isDesktop() {
      return this.$store.getters.windowWidthState > this.$breakpoints.mobile;
    },
  },
  methods: {
    handleClickReadMore(member) {
      this.$modal.show(TeamMemberDetails, {member}, this.modalParams);
    },
  },
  components: {TeamMember},
};
</script>

<style scoped lang="scss">
@import '@common/style/info.page';

header {
  height: 120px;
  min-height: auto;
}

.info_page {
  .info_section {
    &:nth-child(even) {
      background-color: $background-color;
    }
  }
}
.team {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  &::after {
    content: '';
    flex: auto;
  }

  @media only screen and (max-width: $breakpoint-desktop) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
}

#team {
  .info_section_content {
    padding-bottom: 0;
  }
}
</style>
