<template>
  <div class="mcr-news-page">
    <div class="readable_content">
      <h1>Press &amp; Media</h1>
      <div id="news_page">
        <div class="filters_wrapper">
          <filters-container></filters-container>
        </div>
        <div class="news_list">
          <mcr-loading-indicator
            v-if="!pressArticlesFilteredState.length && pressArticlesIsLoadingState"
            :loading="pressArticlesIsLoadingState"
          ></mcr-loading-indicator>
          <news-item
            v-for="(theNew, index) in pressArticlesFilteredState"
            :key="index"
            :external-link="theNew.url"
            :image-src="theNew.image"
            :heading="theNew.title"
            :category="theNew.type"
            :description-html="theNew.description"
          >
          </news-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import FiltersContainer from '@/components/modules/aboutUs/press/filtersContainer';
import NewsItem from '@/components/modules/aboutUs/press/newsItem';

export default {
  metaInfo: {
    title: 'Press',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Read what others have written about us in the New York Times, BBC, Who Do You Think You Are, South China Morning Post and more.',
      },
    ],
  },
  name: 'mcr.news.page',
  computed: {
    ...mapGetters(['pressArticlesFilteredState', 'pressArticlesIsLoadingState']),
  },
  mounted() {
    this.$store.dispatch('fetchPressArticleListAction');
  },
  methods: {},
  components: {FiltersContainer, NewsItem},
};
</script>

<style scoped lang="scss">
#news_page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .filters_wrapper {
    border-right: 1px solid $divider-line-color;
    padding-right: 40px;
    margin-top: 30px;
  }

  .news_list {
    width: calc(100% - 220px);
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    flex-wrap: wrap;
    .news_list {
      width: 100%;
    }
    .filters_wrapper {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
